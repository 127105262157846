import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchTranoverDetail } from "@/api/finance";
import Pagination from "@/components/Pagination";
import "@/utils/global.js";
import { transChargeType } from "@/utils/util";
import { getToken } from "@/utils/cache";
import { downloadFun } from "@/utils/util";
export default {
  name: "rechargeList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      searchForm: {
        page: 1,
        per_page: 20,
        id: ''
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      info: {
        agent_name: '',
        property_count: '',
        charging_count: '',
        settle_money: '',
        avg_usage_rate: ''
      }
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchTranoverDetail(data).then(function (response) {
        console.log(response);
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        console.log(response.meta);

        _this.transTotalDate(response.meta);
      }).catch(function (err) {
        console.log(err);
      });
      this.tableLoading = false;
    },
    init: function init() {
      // 获取明细
      if (this.$route.query && this.$route.query.id) {
        this.admin = this.$route.query.name;
        this.searchForm['id'] = this.$route.query.id;
        this.getTableData();
      }
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    transTotalDate: function transTotalDate(data) {
      var info = this.info;

      for (var i in info) {
        if (data[i]) {
          info[i] = data[i];
        }
      }

      console.log(this.info, 'infoooo');
    },
    transChargeType: transChargeType
  }
};