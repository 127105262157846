var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "total-container" },
        [
          _c("el-row", [
            _c("span", [_vm._v("代理商：" + _vm._s(_vm.info.agent_name) + " ")])
          ]),
          _c("el-row", [
            _c("span", [
              _vm._v("小区数量：" + _vm._s(_vm.info.property_count))
            ]),
            _c("span", [
              _vm._v("充电桩数量：" + _vm._s(_vm.info.charging_count))
            ]),
            _c("span", [_vm._v("结算金额：" + _vm._s(_vm.info.settle_money))]),
            _c("span", [
              _vm._v("平均使用率：" + _vm._s(_vm.info.avg_usage_rate))
            ]),
            _c("span")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "table-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              attrs: { data: _vm.dataList, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "property_name",
                  label: "小区名",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "充电桩类型",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.transChargeType(scope.row.charging_type))
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "quantity",
                  label: "充电桩数量",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "usage_rate",
                  label: "月使用率",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_count",
                  label: "订单数量",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "money",
                  label: "订单金额",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "commission_rate",
                  label: "佣金比例",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "settle_money",
                  label: "结算金额",
                  "header-align": "center",
                  align: "center"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.totalPage > 0,
            expression: "totalPage>0"
          }
        ],
        attrs: {
          total: _vm.totalPage,
          page: _vm.searchForm.page,
          limit: _vm.searchForm.per_page
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchForm, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchForm, "per_page", $event)
          },
          pagination: _vm.getTableData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }